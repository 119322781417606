(function($) {

    $(window).on('load', function () {

        // Components loading animations
        $('.view-animation').viewportChecker({
            classToAdd: 'animated',
            offset: 20
        });

        // Accordion for FAQs (jQuery)
        $('.accordion dt.active').next().slideDown()

        $('.accordion').on('click', 'dt', function () {
            $('.accordion dd').slideUp();

            if (!$(this).hasClass('active')) {
                // remove active class from any others that might be active
                $('.accordion dt.active').removeClass('active');
                $(this).addClass('active');
                $(this).next().slideDown();
            } else {
                $(this).removeClass('active');
            }
        });

        $('a[href*="#"]').on('click', function(e){
            e.preventDefault();

            $(window).scrollTop($($(this).attr('href')).offset().top - $('header').outerHeight());
        });


        // Inline Video Funcionality
        $(document).on('click', '.inline-video-trigger', function () {
            if ($(this).data('video-id')) {
                if ($(this).hasClass('vimeo')) {
                    var iframeHTML = '<iframe src="https://www.vimeo.com/embed/' + $(this).attr('data-video-id') + '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
                } else {
                    var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + $(this).attr('data-video-id') + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                }

                $(this).parent('.video-preview-container').find('.inline-video-trigger').hide();
                $(this).parent('.video-preview-container').find('.overlay').hide();
                $(this).parent('.video-preview-container').find('iframe').remove();
                $(this).parent('.video-preview-container').append(iframeHTML);
            } else {
                console.error('no video ID provided.');
            }
        });

        // Slick Slider (jQuery) - Remove these if not in use 
        // $('.testimonials .slider').slick({
        //     infinite: true,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     speed: 600,
        //     dots: true,
        //     autoplay: true,
        //     autoplaySpeed: 5000,
        //     prevArrow: $('.testimonials .prev'),
        //     nextArrow: $('.testimonials .next')
        // });

        // Gallery Slider
        if($('#section-13.video-gallery .slider .slide').length > 1){
            $('#section-13.video-gallery .slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 600,
                prevArrow: $('.video-gallery .prev'),
                nextArrow: $('.video-gallery .next'),
                fade: true,
                dots: true,
            });
        }

        $('#section-13-2.video-gallery .slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 600,
            prevArrow: $('.video-gallery .prev'),
            nextArrow: $('.video-gallery .next'),
            fade: true,
            dots: true,
        });


    });

    var resizeTimer;
    $(window).bind('resize load', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            // Responsive slider for blocks section
            $('#section-2 .slider').not('.slick-initialized').slick({
                infinite: true,
                mobileFirst: true,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000, 
                arrows: false,
                dots: true,
                responsive: [ 
                    {
                        breakpoint: 1179, 
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 4,
                        }
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                ]
            });

            $('#section-5 .slider').not('.slick-initialized').slick({
                infinite: true,
                mobileFirst: true,
                speed: 600,
                slidesToShow: 2,
                slidesToScroll: 2,
                autoplay: true,
                autoplaySpeed: 5000, 
                arrows: false,
                dots: true,
                responsive: [ 
                    {
                        breakpoint: 991, 
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                ]
            });


            $('.reviews-slider').not('.slick-initialized').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                speed: 600,
                fade: false,
                dots: true,
                arrows: false,
                responsive: [ 
                    {
                        breakpoint: 991, 
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                ]
            });


        }, 500)
    })

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    });

})( jQuery );
